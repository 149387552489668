@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Open Sans';
  background-color: $background;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #root {
    flex: none;

    .container {
      $map: (320px: 320px, 481px: 481px, 769px: 469px, 1025px: 512px, 1201px: 720px);
      @include poly-fluid-sizing("width", $map);

      h1 {
        $map: (320px: 30px, 481px: 40px, 769px: 46px, 1025px: 52px, 1201px: 65px);
        @include poly-fluid-sizing("font-size", $map);
      }

      p {
        $map: (320px: 16px, 481px: 18px, 769px: 22px, 1025px: 24px, 1201px: 26px);
        @include poly-fluid-sizing("font-size", $map);
      }

      .btn {
        $map: (320px: 14px, 481px: 16px, 769px: 18px, 1025px: 20px, 1201px: 22px);
        @include poly-fluid-sizing("font-size", $map);
      }
      
      .btn:focus {
        box-shadow: 0 0 0 0.5rem rgba(13, 37, 253, 0.411);
      }
    }
  }
}
