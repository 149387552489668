#blocks_and_button {
  .bg-space {
    background-color: $bg-space;
  }

  .bg-correct {
    background-color: $bg-correct;
  }

  input {
    $map: (320px: 16px, 481px: 18px, 769px: 22px, 1025px: 24px, 1201px: 26px);
    @include poly-fluid-sizing("font-size", $map);
  }

  .button-next {
    background-color: $button-next;
  }
}
